/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

/* APIS */
import { getBlogs } from "../service/api";

/* CONFIG */
import { IMAGE_URL } from "../config";

const BlogBox = (props) => {
  return (
    <Link
      to={"/blog-detail/" + props?.blogDetails?._id}
      style={{ textDecoration: "none" }}
    >
      <Card className="blog-box">
        <Card.Body className="blog-box-body">
          <img src={IMAGE_URL + props.image} alt={props.title} />
          <div className="details">
            <h5>{props.title}</h5>
          </div>
        </Card.Body>
        <Card.Footer className="blog-box-footer">
          <p className="date">
            {moment(`${new Date(props?.datePublished)}`).format(
              "DD MMMM, YYYY"
            )}
          </p>
        </Card.Footer>
      </Card>
    </Link>
  );
};

const AboutAndBlogs = () => {
  /* STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [blogsData, setBlogsData] = useState([]);

  /* GET BLOGS LISTINGS */
  const getBlogsList = () => {
    setLoading(true);
    const obj = {};
    obj.page = page;
    obj.sizePerPage = pageSize;

    getBlogs(obj)
      .then((res) => {
        if (res?.status) {
          setBlogsData(res?.data?.docs);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });

    return () => {
      setBlogsData([]);
    };
  };

  /* GET BLOGS ONLOAD */
  useEffect(() => {
    getBlogsList();
  }, []);

  useEffect(() => {
    getBlogsList();
  }, [page]);

  return (
    <section className="about-and-blogs">
      <div className="bg-overlay">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-4 col-sm-12">
              <img
                src={require("../assets/creola-jefferson-profile.webp")}
                alt="Rene Nava"
              />
            </div>
            <div className="col-xl-7 col-sm-12">
              <div className="row">
                <div className="col-xl-10 col-sm-12">
                  <div className="heading">
                    <h2>Rene Nava</h2>
                    <p>
                      Meet Rene Nava, a seasoned Real Estate Professional with
                      over thirty years of experience.
                    </p>
                    <p>
                      I specializes in both commercial and residential
                      properties. My extensive network of contacts enables me to
                      put transactions together that intimidate less experienced
                      agents. If you are looking to engage one of the top people
                      in the real estate industry, you are in luck. You just
                      found me!
                    </p>
                    <p>
                      I am enthusiastic about starting this thrilling journey
                      alongside you! Whether you're a first-time buyer, an
                      experienced investor, or considering selling your
                      property, I am only a call or email away. Let's arrange a
                      meeting to explore your real estate objectives and how I
                      can assist you in reaching them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {blogsData.slice(0, 4).map((blog) => {
              return (
                <div className="col-xl-3 col-lg-6 col-sm-12" key={blog.id}>
                  <BlogBox
                    image={blog?.image}
                    title={blog?.name}
                    datePublished={blog?.startDate}
                    blogDetails={blog}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAndBlogs;
