import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

/* IMAGE */
import AmberwoodRELogo from "../assets/amberwood-re-logo.webp";
import SoCalHomeGuy from "../assets/socalhomeguy-logo.png";

const Header = () => {
  return (
    <>
      <header className="header-3-desktop">
        <div className="container">
          <div className="header-wrap">
            <div className="left-section">
              <Link to="/buying" className="link-item">
                BUYING
              </Link>
              <Link to="/selling" className="link-item">
                SELLING
              </Link>
              <Link to="/home-evaluation" className="link-item">
                HOME EVALUATION
              </Link>
            </div>
            <div className="center-section">
              <Link to="/" style={{ textDecoration: "none" }}>
                <div className="d-flex align-items-center">
                  <img
                    src={SoCalHomeGuy}
                    className="logo-personal"
                    alt="Souther California Home Guy"
                  />
                  <p>x</p>
                  <img
                    src={AmberwoodRELogo}
                    className="company-logo"
                    alt="Amberwood RE"
                  />
                </div>
              </Link>
            </div>
            <div className="right-section">
              <Link to="/find-a-home" className="link-item">
                FIND A HOME
              </Link>
              <Link to="/blogs" className="link-item">
                BLOGS
              </Link>
              <Link to="/contact-me" className="link-item">
                CONTACT ME
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/* MOBIE HEADER */}
      <header className="header-3-mobile">
        <div className="container">
          <div className="header-links">
            <div className="logo-box">
              <img
                src={AmberwoodRELogo}
                className="company-logo"
                alt="Amberwood RE"
              />
              <p>x</p>
              <img
                src={SoCalHomeGuy}
                className="personal-logo"
                alt="Southern California Home Guy"
              />
            </div>
            <div className="navigation-links">
              <Navbar expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Offcanvas id="offcanvas-expand-lg" placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvas-expand-lg">
                      <Link to="/">
                        <div className="logo-box">
                          <img
                            src={AmberwoodRELogo}
                            alt="Amberwood Real Estate Inc."
                          />
                        </div>
                      </Link>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="main-menu">
                      <Nav.Link href="/buying" className="menu-link">
                        BUYING
                      </Nav.Link>
                      <Nav.Link href="/selling" className="menu-link">
                        SELLING
                      </Nav.Link>
                      <Nav.Link href="/home-evaluation" className="menu-link">
                        HOME EVALUATION
                      </Nav.Link>
                      <Nav.Link href="/find-a-home" className="menu-link">
                        FIND A HOME
                      </Nav.Link>
                      <Nav.Link href="/blogs" className="menu-link">
                        BLOGS
                      </Nav.Link>
                      <Nav.Link href="/contact-me" className="menu-link">
                        CONTACT ME
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
